import styled from "@emotion/styled";
import { useRef } from 'react';

import HamIcon from '../Molecules/HamIcon.js';
import HeaderDropMenu from './HeaderDropMenu';
import { BREAKPOINTS } from '../configs/consts'

function HeaderMobile(props) {
  const thisRef = useRef(null);
  const { toggleIsOpen, isOpen, headerConfig, config, menuItems } = props;

  return <StyledHeaderMobile ref={thisRef} config={config} isOpen={isOpen} onClick={() => toggleIsOpen()}>
    <HamIcon size={21} className={isOpen ? 'open' : ''} color={isOpen ? '#FFF' : '#009B67'} />
    <HeaderDropMenu parentRef={thisRef} toggleIsOpen={toggleIsOpen} isOpen={isOpen} menuItems={menuItems} mobileItems={headerConfig?.headerUser?.menuItems} config={config}/>
  </StyledHeaderMobile>
}

export default HeaderMobile;

/* style */

const StyledHeaderMobile = styled.div`
  overflow: hidden;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  background: ${props => props.isOpen ? '#009B67' : 'none'};
  
  @media (min-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    display:none;
  }
`