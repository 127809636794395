import { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import HeaderUserV2 from './HeaderUserV2.js';
import HeaderMobile from './HeaderMobile.js';
import ToolHelper from "../utils/ToolHelper";
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../configs/consts'
import plataforma from '../assets/deuCashFundo.png';
import CoreLink from '../Molecules/CoreLink.js';
import { routesO } from '../../config/routes.js';

const excludedScreensSalesRush = [
  "Meus Resultados",
  "Sorteios",
  "Pesquisas",
  "Clientes Focalizados",
  "Aprovação de Valores",
  "Regulamentos",
];

function HeaderV2Base(props) {
  const [isMenuUserOpen, setIsMenuUserOpen] = useState(false);
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  const [headerConfig, setHeaderConfig] = useState({});

  const { data, userHelper, notification, getConfigFile, routes, openGlobalPopup, history } = props;

  const HIDE_LINKS_AND_MENU = history.location.pathname === "/conferencia-termos-de-uso";

  const HAS_SALES_RUSH = data?.user?.acg_ProfileCodes__c?.includes("rushdevendas");

  const config = useMemo(() => ToolHelper.getThemedConfig(props, 'Header.preset'), [props]);

  //useEffect(() => {
  //   loadHeaderConfig();
  // }, [global.userType])

  // const loadHeaderConfig = async () => {
  //   const importedFile = await ToolHelper.importByUserType('header', getConfigFile);
  //   setHeaderConfig(importedFile.default || []);
  // }

  const toggleMenuUser = (doOpen) => {
    let willOpen = !isMenuUserOpen;

    if (doOpen !== undefined) {
      willOpen = doOpen;
    }

    if (willOpen) {
      toggleMenuMobile(false);
    }

    setIsMenuUserOpen(willOpen);
  };

  const toggleMenuMobile = (doOpen) => {
    let willOpen = !isMenuMobileOpen;

    if (doOpen !== undefined) {
      willOpen = doOpen;
    }

    if (willOpen) {
      toggleMenuUser(false);
    }

    setIsMenuMobileOpen(willOpen);
  };

  const headers = [
    { routeO: routesO.home, label: 'Início' },
    { routeO: routesO.pointList, label: 'Extrato' },
    { routeO: routesO.premmiarVitrine, label: 'Catálogo', target: '_blank' },
    { routeO: routesO.results, label: 'Meus Resultados' },
    // { routeO: routesO.raffles, label: 'Sorteios', target: '_blank' },
    { routeO: routesO.focalPointList, label: 'Minha Equipe' },
    { routeO: routesO.businessCycle, label: 'Aprovação de Valores' },
    { routeO: routesO.focusedList, label: 'Clientes Focalizados' },
  ]

  const menuItems = [
    // { customComponent: 'SwitchAccountLink', label: 'Trocar conta', secondary: true },
    { routeO: routesO.profile, label: 'Minha conta', hideOn: [] },
    { routeO: routesO.incomeReports, label: 'Informe de Rendimentos' },
    { routeO: routesO.regulations, label: 'Regulamentos' },
    { routeO: routesO.caseNew, label: 'Fale Conosco' },
    { routeO: routesO.pointList, label: 'Extrato' },
    { routeO: routesO.premmiarVitrine, label: 'Catálogo', target: '_blank' },
    { routeO: routesO.results, label: 'Meus Resultados' },
    { routeO: routesO.businessCycle, label: 'Aprovação de valores' },
    { routeO: routesO.focusedList, label: 'Clientes Focalizados' },
    { routeO: routesO.logout, label: 'Sair' }
  ]

  return (
    <>
      <StyledHeader headerConfig={headerConfig} showHeader={props.data.showHeader} config={config} data={props.data}>
        {HIDE_LINKS_AND_MENU ? null : (
          <div className={'links-line'}>
            <div className={'links-container'}>
              {headers?.map((link, index) => {
                if (HAS_SALES_RUSH && excludedScreensSalesRush.includes(link.label)) {
                  return;
                }
                return <CoreLinkStyled exact {...link} key={index} />
              })}
            </div>
          </div>
        )}
        <div className={'header-line'}>
          <div className={'header-container'}>
            <Link to={HIDE_LINKS_AND_MENU ? null : routes?.home?.path} className={'link-logo'}>
              <img width='160px' src={plataforma} />
            </Link>
            {/* <div className={'slogan'}>
              <img src={plataforma} />
            </div> */}
            {HIDE_LINKS_AND_MENU ? null : (
              <div className={'side-container'}>
                <HeaderMobile menuItems={menuItems} isOpen={isMenuMobileOpen} toggleIsOpen={toggleMenuMobile} config={config} headerConfig={headerConfig} />
                <HeaderUserV2 menuItems={menuItems} isOpen={isMenuUserOpen} toggleIsOpen={toggleMenuUser} data={data} config={config} userHelper={userHelper} notification={notification} headerConfig={headerConfig} openGlobalPopup={openGlobalPopup} />
              </div>
            )}
          </div>
        </div>
      </StyledHeader>
    </>
  );
}

const HeaderV2 = withRouter(HeaderV2Base)

export default HeaderV2;

/* style */

const StyledHeader = styled.div`
  display: ${props => props.showHeader ? 'block' : 'none'};
  position: sticky;
  top: 0;
  z-index: 9999;

  .links-line {
    background: #009B67;
    flex: 1;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    /* padding: 0 110px; */

    @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
      display: none;
    }

    .links-container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: ${props => props.config?.layout?.CONTAINER_SIZE + 'px' || 'none'};
      margin: auto;
      padding: 10px 0;
    }
  }

  .header-line {
    background: #f6f6f6;
    flex: 1;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    display: flex;
    padding: 20px 0;

    .header-container{
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: ${props => props.config?.layout?.CONTAINER_SIZE + 'px' || 'none'};
      margin: 8px auto;
      padding-left: 20px;
      /* padding: 0 110px; */

      .link-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0;
  
        .logo {
          display: inline-block;
          width: clamp(100px,calc(20vw),225px);
        }
      }
  
      .slogan{
        font-weight: bold;
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: max(2vw,10px);

        img {
          width: clamp(150px,20vw,300px);
        }
      }
  
      .side-container {
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
  }

  .wave-line{
  }
`

export const CoreLinkStyled = styled(CoreLink)`
  text-decoration: none;
  color: #FFFFFF;
  font-size: 1.1em;
  font-weight: bold;
  &.active {
    color: #9BD3AE;
  }
  &:hover {
    opacity: 0.7;
  }

`;
