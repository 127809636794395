const firebaseConfig =  {
  apiKey: "AIzaSyDz5BqNWMp_YWJ7Ed3iV61LVODK-ApdEv0",
  authDomain: "syngenta-1229a.firebaseapp.com",
  projectId: "syngenta-1229a",
  storageBucket: "syngenta-1229a.appspot.com",
  messagingSenderId: "961014963235",
  appId: "1:961014963235:web:13fe95ede592357c5ea596",
  // trackingId: "GTM-KS77X9HB", << Não usar. A tag está inserida manualmente em public/index.html
};


export default firebaseConfig;