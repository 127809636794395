import React, { useState } from 'react';
import ApiService from '../services/ApiService';

const ApiContext = React.createContext();

export const ApiProvider = ({ children }) => {
  const apiService = new ApiService("");
  const [ globalUser, setGlobalUser ] = useState(null);

  return (
    <ApiContext.Provider value={{apiService, globalUser, setGlobalUser}}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;