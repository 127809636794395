import { useState, useRef, useEffect } from 'react';
import DDD from '../configs/ddd';
import { doc, getDoc } from 'firebase/firestore';

const FormHelper = {
  useFormInput: (data) => {

    const ref = useRef();
    const inputRef = useRef();

    const [ error, setError ] = useState('');
    const [ options, setOptions ] = useState([]); // opções de um <select>
    const [ inputOptions, setInputOptions ] = useState({}); // configurações de um input (ie disabled="disabled")
    const [ isRequired, setIsRequired ] = useState(false);
    const [ isVisible, setIsVisible ] = useState(false);
    const [ type, setType ] = useState(data.type);
    const [ loading, setLoading] = useState(false);

    useEffect(() => {
      const isRequired = !!data?.validations?.filter((validation) => validation?.type === 'required').length;
      setIsRequired(isRequired);
    },[data.validations])

    const getValue = () => inputRef?.current?.value || '';

    const setValue = (value) => {
      if(value !== undefined && inputRef?.current){
        inputRef.current.value = value;
        inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }

    const setFocus = () => inputRef?.current?.focus();

    const focusError = (message) => {
      setError(message);
      setFocus();
    }

    const reset = () => {
      ref.current.reset();
    }

    useEffect(() => {
      if(data.options){
        setOptions(data.options);
      }

      if(data.inputOptions){
        setInputOptions(data.inputOptions);
      }
    },[])

    return {
      ...data,

      inputOptions,
      ref,
      inputRef,
      error,
      options,

      setValue,
      getValue,
      type,
      setType,
      setInputOptions,
      setError,
      setFocus,
      focusError,
      setOptions,
      isRequired,
      setIsVisible,
      isVisible,
      reset,
      setLoading,
      loading,
    }
  },

  validateEmail: function (email) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email);
  },
  
  validateCNPJ : (cnpj) => {
    var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
    var c = String(cnpj).replace(/[^\d]/g, '')
    
    if(c.length !== 14)
        return false

    if(/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
  },

  validatePasswordTips: function (formInput) {
    let value = formInput.getValue();

    if(!formInput?.contentTips){
      return true;
    }

    let isValid = true;
    
    for(let i in formInput.contentTips){
      let [type,qty] = formInput.contentTips[i].split(':');
      if(!this.validatePasswordCompositionItem(value,type,qty)){
        isValid = false;
      }
    }

    return isValid;
  },

  validatePersonName: (name) => {
    // Deve ter 2 ou mais caracteres e ser composto apenas de letras, letras acentuadas e espaços
    return name &&
      name.length >= 2 &&
      /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g.test(name)
  },

  isNumeric : (value) => {
    return /^\d+(?:\.\d+)?$/.test(value);
  },

  validateCPF: (cpf) => {
    if (typeof cpf !== 'string') return false
    
    cpf = cpf.replace(/[^\d]+/g, '')
    
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
    
    cpf = cpf.split('')
    
    const validator = cpf
        .filter((digit, index, array) => index >= array.length - 2 && digit)
        .map( el => +el )
        
    const toValidate = pop => cpf
        .filter((digit, index, array) => index < array.length - pop && digit)
        .map(el => +el)
    
    const rest = (count, pop) => (toValidate(pop)
        .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) 
        % 11 
        % 10
        
    return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1])
  },

  validateUsername: function (username) {
    let usernameType = this.detectStringContent(username);

    switch(usernameType){
      case 'cpf':
        if(!this.validateCPF(username)){
          return false;
        }
      break;
      case 'cnpj':
        if(!this.validateCNPJ(username)){
          return false;
        }
      break;
      case 'email':
        if(!this.validateEmail(username)){
          return false;
        }
      break;
      default:
        return false;
    }

    return true;
  },

  validateAge: ({date,minAge,maxAge}) => {
    if(!date){
      return false;
    }

    if(date.search('-') === -1)
      date = date.split('/').reverse().join('-');

    // precisa ter formato YYYY-MM-DD
    if(date.match(/^\d{4}-\d{2}-\d{2}$/) === null){
      return false;
    }

    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return (minAge === undefined || minAge <= age) && (maxAge === undefined || age <= maxAge)
  },
  validatePasswordCompositionItem: function (value,type,qty) {
    let isSatisfied = false;

    switch(type){
      case 'chars':
        if(value.length >= qty){
          isSatisfied = true
        }
      break;
      case 'uppercase':
        if(value.length - value.replace(/[A-Z\u00C0-\u00DC]+/g,'').length >= qty){
          isSatisfied = true
        }
      break;
      case 'lowercase':
        if(value.length - value.replace(/[a-z\u00E0-\u00FC]+/g,'').length >= qty){
          isSatisfied = true
        }
      break;
      case 'number':
        if(value.length - value.replace(/[0-9]+/g,'').length >= qty){
          isSatisfied = true
        }
      break;
      case 'symbol':
        if(value.length - value.replace(/[ `´!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/g,'').length >= qty){
          isSatisfied = true
        }
      break;
    }

    return isSatisfied;
  },
  validateRequiredFields: (inputs,data) => {
    let aInputs = Object.entries(inputs);
    let requiredFields = [];
    aInputs.every(aInput => {
      if(aInput[1].isRequired)
        requiredFields.push(aInput[0]);
      return true;
    })
    
    let aData = Object.entries(data);

    for(let i=0;i<aData.length;i++){
      let index = requiredFields.indexOf(aData[i][0]);
      if(index !== -1){
        requiredFields.splice(index,1);
      }
    }
    
    if(requiredFields.length > 0){
      console.log('Falhou validação de campos obrigatórios!',requiredFields)
      return false;
    }

    return true;
  },
  validateCellphone: (string) => {
    const numbers = (string || '').replace(/\D/g,'');

    if(numbers.length !== 11){
      return false;
    }

    const ddd = numbers.slice(0,2);

    if(!DDD.includes(ddd)){
      return false;
    }

    return true;
  },
  // Detecta se string tem padrão de e-mail, cpf, cnpj ou um simples texto
  detectStringContent: (string) => {
    let noLettersString = string?.replace(/[a-zA-Z]/g,'')
    // cpf?
    if(/^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/g.test(noLettersString)){
      // parece cpf
      return 'cpf';
    }
    // cnpj?
    else if(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/g.test(noLettersString)){
      // parece cnpj
      return 'cnpj';
    }
    else if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(string)){
      // parece e-mail
      return 'email';
    }

    return 'text';
  },
  getAddressByCEP: async (cep) => {
    const genericCep = String(cep).split('-')[1];
    let result = {
      isComplete: false,
      isValid: false,
      address: {},
    }

    if(!cep || typeof cep !== 'string')
      return result;
    
    cep = cep.replace(/\D/g,'');

    if(cep.length !== 8)
      return result;
    
    var response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    
    if(response.ok){
      let jresponse = await response.json();

      if(jresponse.localidade){
        let streetGeneric;
        let borhoodGeneric;
        
        if(genericCep === '000') {
          const getUser = doc(global.dbForCEP, 'Users', global.userFirebaseId);
          if(getUser) {
            let userDoc = await getDoc(getUser);
            if (userDoc.exists()) {
              const data = userDoc.data();
              streetGeneric = data.LoyaltyStreet__c;
              borhoodGeneric= data.acg_AddressDistrict__c;
            };
          };
        };

        let address = {
          street: streetGeneric ? streetGeneric : jresponse.logradouro,
          complement: jresponse.complemento,
          neighborhood: borhoodGeneric ? borhoodGeneric : jresponse.bairro,
          city: jresponse.localidade,
          uf: jresponse.uf,
        }

        return {
          isComplete: true,
          isValid: true,
          address,
        }
      }
    }

    return {
      isComplete: true,
      isValid: false,
      address: {},
    }
  }
}

export default FormHelper;