import { lazy } from 'react';

export const routesO = {
  // any state paths
  sso: {
    path: '/sso',
    access: 'public',
    component: lazy(() => import('../screens/Login/Sso')),
    exact: true,
    meta: {
      title: 'SSO',
    },
  },
  terms: {
    path: '/termos-de-uso',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Terms')),
    exact: true,
    meta: {
      title: 'Termos de Uso',
    },
  },
  termVersion: {
    path: '/termos-de-uso/:id',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Terms')),
    exact: true,
    meta: {
      title: 'Termos de Uso',
    },
  },
  privacy: {
    path: '/politica-de-privacidade',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Privacy')),
    exact: true,
    meta: {
      title: 'Política de Privacidade',
    },
  },
  regulation: {
    path: '/regulamento',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Regulation')),
    exact: true,
    meta: {
      title: 'Regulamento',
    },
  },
  accessCode: {
    path: '/codigo-de-acesso',
    access: 'public',
    component: lazy(() => import('../screens/Login/AccessCode')),
    exact: true,
    meta: {
      title: 'Código de Acesso',
    },
  },
  definePassword: {
    path: '/definir-senha',
    access: 'public',
    component: lazy(() => import('../screens/Login/DefinePassword')),
    exact: true,
    meta: {
      title: 'Definir Senha',
    },
  },
  caseNew: {
    path: '/fale-conosco',
    access: 'public',
    component: lazy(() => import('../screens/Case/CaseNew')),
    exact: true,
    meta: {
      title: 'Fale Conosco - Enviar contato',
    },
  },
  premmiarTest: {
    path: '/teste-premmiar',
    access: 'public',
    component: lazy(() => import('../screens/Premmiar/PremmiarTest')),
    meta: {
      title: 'Teste Premmiar',
    },
  },
  logout: {
    path: '/logout',
    access: 'public', // logout é public pra ignorar restrições de código de acesso e termos de uso
    component: lazy(() => import('../screens/Login/Logout')),
    exact: true,
    meta: {
      title: 'Logout',
    },
  },
  premmiarVitrine: {
    path: '/vitrine-premmiar',
    access: 'logged',
    component: lazy(() => import('../screens/Premmiar/PremmiarGoToVitrine')),
    exact: true,
    meta: {
      title: 'Premmiar',
    }
  },

  // logged paths
  home: {
    path: '/home',
    access: 'logged',
    componentByType: {
      default: lazy(() => import('../screens/Home/HomeV2')),
    },
    exact: true,
    meta: {
      title: 'Início',
    },
  },
  gameDashboard: {
    path: '/dashboard',
    access: 'logged',
    component: lazy(() => import('../screens/Gamefication/GameDashboard')),
    exact: true,
    meta: {
      title: 'Dashboard',
    },
  },
  pointList: {
    path: '/extrato',
    access: 'logged',
    component: lazy(() => import('../screens/Gamefication/PointList')),
    exact: true,
    meta: {
      title: 'Meu extrato',
      description: 'Extrato de pontos no programa Acessa Agro',
    },
  },
  focusedList: {
    path: '/focalizados',
    access: 'logged',
    component: lazy(() => import('../screens/FocusedCustomers/FocusedList')),
    exact: true,
    meta: {
      title: 'Clientes Focalizados',
      description: 'Lista de clientes focalizados',
    },
    allowedTypes: [
      'Analistadefocalizacao',
    ],
  },
  focusedConsumption: {
    path: '/focalizados-consumo',
    access: 'logged',
    component: lazy(() => import('../screens/FocusedCustomers/FocusedConsumption')),
    exact: true,
    meta: {
      title: 'Consumo',
      description: 'Consumo',
    },
  },
  focusedProfile: {
    path: '/focalizados-perfil',
    access: 'logged',
    component: lazy(() => import('../screens/FocusedCustomers/FocusedProfile')),
    exact: true,
    meta: {
      title: 'Perfil Cliente',
      description: 'Perfil Cliente',
    },
  },
  focusedExtractTansactions: {
    path: '/focalizados-extrato-transacoes',
    access: 'logged',
    component: lazy(() => import('../screens/FocusedCustomers/FocusedExtractTransactions')),
    exact: true,
    meta: {
      title: 'Extrato de Transações',
      description: 'Extrato de Transações',
    },
  },
  reward: {
    path: '/premios',
    access: 'logged',
    component: lazy(() => import('../screens/Reward/RewardList')),
    exact: true,
    meta: {
      title: 'Prêmios',
    },
  },
  benefit: {
    path: '/beneficios',
    access: 'logged',
    component: lazy(() => import('../screens/Comp/Comp.tsx')),
    exact: true,
    meta: {
      title: 'Benefícios',
    },
  },
  challenge: {
    path: '/desafios',
    access: 'logged',
    component: lazy(() => import('../screens/Challenge/ChallengeList')),
    exact: true,
    meta: {
      title: 'Desafios',
    },
  },
  news: {
    path: '/noticias',
    access: 'logged',
    component: lazy(() => import('../screens/News/NewsList')),
    exact: true,
    meta: {
      title: 'Notícias',
    },
  },
  article: {
    path: '/noticias/:articleId',
    access: 'logged',
    component: lazy(() => import('../screens/News/Article')),
    exact: true,
    meta: {
      title: 'Artigos',
    },
  },
  howToEarn: {
    path: '/como-ganhar',
    access: 'logged',
    component: lazy(() => import('../screens/HowToEarn/HowToEarnList')),
    exact: true,
    meta: {
      title: 'Como ganhar',
    },
  },
  referFriend: {
    path: '/indique',
    access: 'logged',
    component: lazy(() => import('../screens/Login/ReferFriend')),
    exact: true,
    meta: {
      title: 'Indique um amigo',
    },
  },
  download: {
    path: '/baixe',
    access: 'logged',
    component: lazy(() => import('../screens/Comp/Comp.tsx')),
    exact: true,
    meta: {
      title: 'Baixe o app',
    },
  },
  profile: {
    path: '/meu-cadastro',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Meu cadastro',
    },
  },
  photo: {
    path: '/meu-cadastro/foto',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Meu cadastro - Foto',
    },
  },
  closeAccount: {
    path: '/meu-cadastro/encerrar-conta',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Encerrar conta',
    },
  },
  changePassword: {
    path: '/meu-cadastro/alterar-senha',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Alterar senha',
    },
  },
  termsAgreement: {
    path: '/conferencia-termos-de-uso',
    access: 'logged',
    component: lazy(() => import('../screens/Login/TermsAgreement')),
    exact: true,
    meta: {
      title: 'Conferência dos Termos de Uso',
    },
  },
  caseDetail: {
    path: '/fale-conosco/detalhe/:caseId',
    access: 'logged',
    component: lazy(() => import('../screens/Case/CaseDetail')),
    exact: true,
    meta: {
      title: 'Fale conosco - Detalhe',
    },
  },
  focalPointList: {
    path: '/minha-equipe',
    access: 'logged',
    component: lazy(() => import('../screens/FocalPoint/FocalPointList.js')),
    exact: true,
    meta: {
      title: 'Minha equipe',
    },
    allowedTypes: ['Focalpoint'],
  },
  focalPointTermList: {
    path: '/historico-de-termos',
    access: 'logged',
    component: lazy(() => import('../screens/FocalPoint/FocalPointTermList')),
    exact: true,
    meta: {
      title: 'Histórico de termos',
    },
  },
  businessCycle: {
    path: '/aprovacao-de-valores',
    access: 'logged',
    component: lazy(() => import('../screens/BusinessCycle/BusinessCycleList')),
    exact: true,
    meta: {
      title: 'Aprovação de valores',
    },
    allowedTypes: ['Focalpoint'],
  },
  businessCycleConsolidatedPerformance: {
    path: '/aprovacao-de-valores/desempenho-consolidado',
    access: 'logged',
    component: lazy(() => import('../screens/BusinessCycle/ConsolidatedPerformance')),
    exact: true,
    meta: {
      title: 'Desempenho consolidado',
    },
    allowedTypes: ['Focalpoint'],
  },
  businessCycleConsolidatedPerformanceResults: {
    path: '/aprovacao-de-valores/desempenho-consolidado/resultados',
    access: 'logged',
    component: lazy(() => import('../screens/Results/ResultsList')),
    exact: true,
    meta: {
      title: 'Desempenho consolidado - Resultados',
    },
    allowedTypes: ['Focalpoint'],
  },
  results: {
    path: '/meus-resultados',
    access: 'logged',
    component: lazy(() => import('../screens/Results/ResultsList')),
    exact: true,
    meta: {
      title: 'Meus resultados',
    },
    allowedTypes: [
      'Consultor',
      'Gerente',
      'Gerentefilial',
      'Geradordedemanda',
      'Telemarketing',
      'Vendedorderota',
    ],
  },
  raffleWinners: {
    path: '/sorteios/:id/ganhadores',
    access: 'logged',
    component: lazy(() => import('../screens/Raffles/RaffleWinners')),
    exact: true,
    meta: {
      title: 'Ganhadares',
    },
  },
  luckyNumbers: {
    path: '/sorteios/:id/numeros-da-sorte',
    access: 'logged',
    component: lazy(() => import('../screens/Raffles/LuckyNumbers')),
    exact: true,
    meta: {
      title: 'Números da sorte',
    },
  },
  raffleRegulation: {
    path: '/sorteios/:id/regulamento',
    access: 'logged',
    component: lazy(() => import('../screens/Raffles/RaffleRegulation')),
    exact: true,
    meta: {
      title: 'Regulamento',
    },
  },
  raffle: {
    path: '/sorteios/:id',
    access: 'logged',
    component: lazy(() => import('../screens/Raffles/RaffleScreen')),
    exact: true,
    meta: {
      title: 'PROMOÇÕES',
    },
  },
  // raffles: {
  //   path: '/sorteios',
  //   access: 'logged',
  //   component: lazy(() => import('../screens/Raffles/RafflesList')),
  //   exact: true,
  //   meta: {
  //     title: 'Sorteios',
  //   },
  //   allowedTypes: [
  //     'Consultor',
  //     'Gerente',
  //     'Gerentefilial',
  //     'Geradordedemanda',
  //     'Telemarketing',
  //     'Vendedorderota',
  //   ],
  // },
  regulations: {
    path: '/regulamentos',
    access: 'logged',
    component: lazy(() => import('../screens/Regulations/RegulationsList')),
    exact: true,
    meta: {
      title: 'Sorteios',
    },
  },
  incomeReports: {
    path: '/informe-de-rendimentos',
    access: 'logged',
    component: lazy(() => import('../screens/IncomeReport/IncomeReportList')),
    exact: true,
    meta: {
      title: 'Informe de rendimentos',
    },
    allowedTypes: [
      'Consultor',
      'Gerente',
      'Gerentefilial',
      'Geradordedemanda',
      'Telemarketing',
      'Vendedorderota',
    ],
  },
  myAccount: {
    path: '/minha-conta',
    access: 'logged',
    component: lazy(() => import('../screens/MyAccount/MyAccount')),
    exact: true,
    meta: {
      title: 'Minha Conta',
    },
  },

  // exclusively unlogged paths
  homeDeslogada: {
    path: '/',
    access: 'unlogged',
    component: lazy(() => import('../screens/Home/Home')),
    exact: true,
    meta: {
      title: 'Home',
    },
  },
  login: {
    path: '/login',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/Login')),
    exact: true,
    meta: {
      title: 'Entrar',
    },
  },
  resetPassword: {
    path: '/redefinir-senha',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/ForgotPassword')),
    exact: true,
    meta: {
      title: 'Redefinir senha',
    },
  },
  signUp: {
    path: '/cadastro',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/SignUp')),
    exact: true,
    meta: {
      title: 'Cadastro',
    },
  },
  raffleWinners2: {
    path: '/sorteios/mobile/:userId/:id/ganhadores',
    access: 'public',
    component: lazy(() => import('../screens/Raffles/RaffleWinners')),
    exact: true,
    meta: {
      title: 'Ganhadares',
    },
  },
  luckyNumbers2: {
    path: '/sorteios/mobile/:userId/:id/numeros-da-sorte',
    access: 'public',
    component: lazy(() => import('../screens/Raffles/LuckyNumbers')),
    exact: true,
    meta: {
      title: 'Números da sorte',
    },
  },
  raffleRegulation2: {
    path: '/sorteios/mobile/:userId/:id/regulamento',
    access: 'public',
    component: lazy(() => import('../screens/Raffles/RaffleRegulation')),
    exact: true,
    meta: {
      title: 'Regulamento',
    },
  },
  raffle2: {
    path: '/sorteios/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/Raffles/RaffleScreen')),
    exact: true,
    meta: {
      title: 'PROMOÇÕES',
    },
  },
  raffles2: {
    path: '/sorteios/mobile/:userId',
    access: 'public',
    component: lazy(() => import('../screens/Raffles/RafflesList')),
    exact: true,
    meta: {
      title: 'Sorteios',
    },
  },
  promotionsConsultant: {
    path: '/acelere-e-ganhe-produtor/:id',
    access: 'logged',
    component: lazy(() => import('../screens/PromotionsProducer/PromotionProducerScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE',
    },
  },
  promotionsConsultantLuckyNumbers: {
    path: '/acelere-e-ganhe-produtor/numeros-da-sorte/:id',
    access: 'logged',
    component: lazy(() => import('../screens/PromotionsProducer/ProducerLuckyNumbersScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE',
    },
  },
  promotionsProducerRegulation: {
    path: '/acelere-e-ganhe-produtor/regulamento/:id',
    access: 'logged',
    component: lazy(() => import('../screens/PromotionsProducer/ProducerRegulationScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE',
    },
  },
  promotionsProducerRafflesScreen: {
    path: '/acelere-e-ganhe-produtor/ganhadores/:id',
    access: 'logged',
    component: lazy(() => import('../screens/PromotionsProducer/ProducerRafflesScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE',
    },
  },

  promotionsConsultant2: {
    path: '/acelere-e-ganhe-produtor/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/PromotionsProducer/PromotionProducerScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE',
    },
  },
  promotionsConsultantLuckyNumbers2: {
    path: '/acelere-e-ganhe-produtor/numeros-da-sorte/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/PromotionsProducer/ProducerLuckyNumbersScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE',
    },
  },
  promotionsProducerRegulation2: {
    path: '/acelere-e-ganhe-produtor/regulamento/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/PromotionsProducer/ProducerRegulationScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE',
    },
  },
  promotionsProducerRafflesScreen2: {
    path: '/acelere-e-ganhe-produtor/ganhadores/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/PromotionsProducer/ProducerRafflesScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE',
    },
  },
  promotionsProducerInvoices: {
    path: '/acelere-e-ganhe-produtor/notas-fiscais',
    access: 'public',
    component: lazy(() => import('../screens/PromotionsProducer/ProducerInvoicesScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE',
    },
  },
  promotionScreen: {
    path: '/acelere-e-ganhe/:id',
    access: 'logged',
    component: lazy(() => import('../screens/Promotions/PromotionScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE'
    },
  },
  promotionScreenMobile: {
    path: '/acelere-e-ganhe/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/Promotions/PromotionScreen')),
    exact: true,
    meta: {
      title: 'ACESSA AGRO - ACELERE E GANHE'
    },
  },
  promotionLuckyNumbersMobile: {
    path: '/acelere-e-ganhe/numeros-da-sorte/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/Promotions/PromotionLuckyNumbers')),
    exact: true,
    meta: {
      title: 'Números da Sorte'
    },
  },
  promotionLuckyNumbers: {
    path: '/acelere-e-ganhe/numeros-da-sorte/:id',
    access: 'logged',
    component: lazy(() => import('../screens/Promotions/PromotionLuckyNumbers')),
    exact: true,
    meta: {
      title: 'Números da Sorte'
    },
  },
  promotionRaffleRegulation: {
    path: '/acelere-e-ganhe/regulamento/:id',
    access: 'logged',
    component: lazy(() => import('../screens/Promotions/PromotionRegulation')),
    exact: true,
    meta: {
      title: 'Regulamento',
    },
  },
  promotionRaffleRegulationMobile: {
    path: '/acelere-e-ganhe/regulamento/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/Promotions/PromotionRegulation')),
    exact: true,
    meta: {
      title: 'Regulamento',
    },
  },
  promotionRaffles: {
    path: '/acelere-e-ganhe/ganhadores/:id',
    access: 'logged',
    component: lazy(() => import('../screens/Promotions/PromotionRafflesScreen')),
    exact: true,
    meta: {
      title: 'Sorteios',
    },
  },
  promotionRafflesMobile: {
    path: '/acelere-e-ganhe/ganhadores/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/Promotions/PromotionRafflesScreen')),
    exact: true,
    meta: {
      title: 'Sorteios',
    },
  },
  promotionsClientsAccept: {
    path: '/acelere-e-ganhe/clientes-aceite/:id',
    access: 'logged',
    component: lazy(() => import('../screens/Promotions/PromotionClientsAccept')),
    exact: true,
    meta: {
      title: 'Sorteios',
    },
  },
  promotionsClientsAcceptMobile: {
    path: '/acelere-e-ganhe/clientes-aceite/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/Promotions/PromotionClientsAccept')),
    exact: true,
    meta: {
      title: 'Sorteios',
    },
  },
  promotionsTopTen: {
    path: '/acelere-e-ganhe/top10/:id',
    access: 'logged',
    component: lazy(() => import('../screens/Promotions/PromotionTopTen')),
    exact: true,
    meta: {
      title: 'Sorteios',
    },
  },
  promotionsTopTenMobile: {
    path: '/acelere-e-ganhe/top10/mobile/:userId/:id',
    access: 'public',
    component: lazy(() => import('../screens/Promotions/PromotionTopTen')),
    exact: true,
    meta: {
      title: 'Sorteios',
    },
  },

  // Redirects
  acaContact: {
    status: 301,
    access: 'redirect',
    path: '/ACA_Contact',
    to: '/fale-conosco',
  },
};

const routes = Object.entries(routesO).map(([key, value]) => value);

export default routes;