import { NavLink } from "react-router-dom";
import { useWindowSize } from "../utils/useWindowSize";
import { BREAKPOINTS } from "../configs/consts";

const CoreLink = props => {
  let { to, url, target, children, exact, className, routeO, label } = props;

  const { width: widthWindow } = useWindowSize();
  const isMobile = widthWindow <= BREAKPOINTS.PHONE;
  const listFilterPerfilHideInvoice = ['Ciacerradokam', 'ciacerradokam'];

  // Alias
  if(url && !to){
    to = url;
  }

  // Alias
  if(label && !children){
    if(label === 'Notas Fiscais' && listFilterPerfilHideInvoice.includes(global.userType)) {
      children = '';
    } else {
      children = label;
    }
  }

  if(routeO){
    // Se foi definido permissão
    if(
      (routeO.allowedTypes && (!global.userTypes || !routeO.allowedTypes.filter(value => global.userTypes.includes(value)).length)) ||
      (routeO.notAllowedTypes && (!global.userTypes || routeO.notAllowedTypes.filter(value => global.userTypes.includes(value)).length))
    ){
      return null;
    }
    
    to = routeO.path;
  }

  if(to?.includes('/meu-cadastro') && isMobile){
    to = '/minha-conta'
  }

  return <NavLink exact={exact} to={to || ''} target={target} className={className}>{children}</NavLink>
}

export default CoreLink;