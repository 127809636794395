export const HEROKU_AUTHORIZATION = 'Bearer 9f9cdb30-a2f6-47d2-8508-fd4f9034f837';

export const HEROKU_REST_URL = '/restHelper';

export const HEROKU_LOYALTY_URL = '/persistLoyalty';

export const HEROKU_REDEEM_URL = '/processRedeem';

export const HEROKU_VOUCHER_URL = '/processVoucher';

export const HEROKU_RECEIPT_URL = '/addReceipt';

export const HEROKU_CASE_URL = '/addCase';

export const HEROKU_INVOICE_URL = '/addInvoice';

export const HEROKU_INVOICE_REANALYSIS_REQUEST_URL = '/requestInvoiceReanalysis';

export const HEROKU_INVOICE_DELETE_URL = '/deleteInvoice';

export const HEROKU_TERM_MEMBER_URL = '/currentSalesTeamMember';

export const HEROKU_MANAGEMENT_TEAM_URL = '/managementSalesTeam';

export const HEROKU_REST_BUSINESS_URL = '/requestRestBusiness';

export const HEROKU_SET_BUSINESS_CYCLE_STATUS_URL = '/setBusinessCycleStatus';

export const HEROKU_SET_POINT_ANTECIPATION_STATUS_URL = '/setPointAntecipationStatus';

export const HEROKU_GET_PERFORMANCE_URL = '/performance';

export const HEROKU_ACCEPT_AGREEMENT = '/acceptAgreement';

export const HEROKU_INVOICE_SYNGENTA_TOTAL_URL = '/getInvoiceSyngentaTotal';

export const HEROKU_AGROLINK_URL = '/agrolink';

export const HEROKU_FOCUSED_ANALYST_CONSUPTION_URL = '/focusedAnalystConsumption';

export const HEROKU_FOCUSED_ANALYST_CUSTOMERS_URL = '/focusedAnalyst';

export const HEROKU_FOCUSED_ANALYST_ACCEPTED_URL = '/focusedAnalystAccepted';

export const HEROKU_FOCUSED_ANALYST_RELATIONSHIP_URL = '/focusedAnalystRelationshipTree';

export const HEROKU_FOCUSED_ANALYST_EXTRACTRANSACTIONS_URL = '/focusedAnalystExtractTransactions';

export const HEROKU_FAVORITE_ACCOUNTS = '/favoriteAccounts';

export const HEROKU_CREATE_POINT_GROUP = '/createPointGroup';

export const HEROKU_VALIDATE_USER = '/validatePointGroupUser';

export const HEROKU_ADD_AND_INVITE = '/addAndInvite';

export const HEROKU_IS_IN_A_GROUP = '/isInAGroup';

export const HEROKU_GET_INVITES = '/getInvites';

export const HEROKU_ACCEPT_INVITE = '/acceptInvite'

export const HEROKU_REJECT_INVITE = '/rejectInvite'

export const HEROKU_SEARCH_CONTACT = '/searchContact';

export const HEROKU_GET_GROUP_DATA = '/getGroupData';

export const HEROKU_INVITE_CONTACT = '/inviteContact';

export const HEROKU_CHANGE_GROUP_NAME = '/changeGroupName';

export const HEROKU_LEAVE_GROUP = '/leaveGroup';

export const HEROKU_REMOVE_FROM_GROUP = '/removeFromGroup';

export const HEROKU_END_GROUP = '/endGroup';

export const HEROKU_PROMOTE_TO_LEADER = '/promoteToLeader';

export const HEROKU_GET_BALANCE_TRANSFER_POINTS_URL = '/getBalanceTransferPoints';

export const HEROKU_GET_USER_BY_DOC = '/getUserByDoc';

export const HEROKU_POST_COMPLETED_TRANSFER = '/completedtransfer';

export const HEROKU_GET_REGULATIONS = '/getRegulations';

export const HEROKU_GET_RECORD_TYPE = '/getRecordType';

export const HEROKU_USER_STATUS = '/userStatus';

export const HEROKU_GET_USER_GROUP = '/getUserGroup';

export const HEROKU_GET_PROFILES_BY_USER_GROUP = '/getProfilesByUserGroup';

export const HEROKU_GET_PERFORMANCE_SALES_TEAM_URL = '/rpc/get_desempenho_time_vendas';

export const HEROKU_GET_PERFORMANCE_SALES_TEAM_ANTICIPATION_URL = '/rpc/get_desempenho_time_vendas_antecipado';

export const HEROKU_GET_PROFILE_CODES = '/getProfileCodes';

export const HEROKU_GET_DISTRIBUTORS_URL = '/getDistributors';

export const HEROKU_GET_USER_INFO_BEFORE_LOGIN = '/getUserInfoBeforeLogin';

export const HEROKU_REMOVE_FAVORITE_ACCOUNT = '/removeFavoriteAccount';

export const HEROKU_GROUP_HISTORY = '/groupHistory';

export const HEROKU_CAMPAIGNS = '/campaigns';
export const HEROKU_CAMPAIGNS_ENROLLMENT = '/campaigns-enrollment';
export const HEROKU_CAMPAIGNS_RESULTS = '/campaigns-results';
export const HEROKU_CAMPAIGNS_MY_NUMBERS = '/campaigns-my-numbers';

export const HEROKU_VERIFY_SWEEPSTAKE_IDS = '/verifySweepstakeIds';
export const HEROKU_GENERIC_INFO = '/generic-info';

export const HEROKU_GET_USER_REFERRAL_CODE = '/getUserByReferralCode';

export const HEROKU_GET_VERIFY_CONTACTS_BY_ACCOUNT = '/verifyContactsByAccount';

export const HEROKU_GET_CONSULTANT_BALANCE = '/getConsultantBalance';

/* PREMMIAR */

export const HEROKU_PREMMIAR_VITRINE_URL = '/premmiar/getVitrine';
export const HEROKU_PREMMIAR_SHOWCASE_URL = '/premmiar/getShowcase';
export const HEROKU_PREMMIAR_SHOWCASE_URL_DESLOG = '/premmiar/getShowcaseToFixedConsultant';


export const HEROKU_PREMMIAR_AVAILABILITY_URL = '/premmiar/getAvailability';
export const HEROKU_PREMMIAR_SHOWCASE_AVAILABILITY_URL = '/premmiar/getShowcaseAvailability';
export const HEROKU_PREMMIAR_MANY_SHOWCASE_AVAILABILITY_URL = '/premmiar/getManyShowcaseAvailability';

export const HEROKU_PREMMIAR_SSO_GETTER_URL = '/premmiar/getSSOURL';

export const HEROKU_PREMMIAR_INCOME_REPORT_URL = '/premmiar/getIncomeReport';

export const HEROKU_PREMMIAR_UPDATE_USER_URL = '/premmiar/updateUser';
