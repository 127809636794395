import CoreLoader from './components/utils/CoreLoader.js';
import firebaseConfig from './config/credentials/firebase';
import LAYOUT from './config/layout/general';
import PRESETS from './config/layout/presets';
import THEME from './config/layout/theme/defaultConfig.js';
import COLORS from './config/layout/colors';
import routes, { routesO } from './config/routes'; // Route list
import Userform from './config/Userform';
import Caseform from './config/Caseform';
import APPCONFIG from './config/appconfig';

import logo from './assets/logo.png';
import placeholder from './assets/placeholder.png';

import  './index.css';

import { ApiProvider } from './context/ApiContext.js';

function App() {
  const configFiles = {
    firebaseConfig,
    APPCONFIG,
    LAYOUT,
    PRESETS,
    THEME,
    COLORS,
    routes,
    routesO,
    Userform,
    Caseform,
    logo,
    placeholder,
    getConfigFile,
  }
  
  return (
    <ApiProvider>
      <CoreLoader configFiles={configFiles} />
    </ApiProvider>
  )
}

export default App;

const getConfigFile = async (path) => {
  let result;

  try{
    result = await import('./config/'+path);
  }
  catch(e){
    result = false;
  }

  return result;
}