import styled from "@emotion/styled";
import { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { BREAKPOINTS } from "../configs/consts";
import ToggleSlider from "../Molecules/ToggleSlider";
import ToolHelper from "../utils/ToolHelper";
import useOutsideAction from "../utils/useOutsideAction";
import CustomLink from "../Molecules/CustomLink";
import CoreLink from "../Molecules/CoreLink";

const HeaderDropMenuItem = (props) => {
  const { config, linkProps, checkboxData, openGlobalPopup, targetPopup, hideOn = [], secondary } = props;
  const { customComponent, label } = linkProps;

  const className = useMemo(() => hideOn.join(" "), [hideOn])

  const openPopup = (e) => {
    e.preventDefault();
    openGlobalPopup(targetPopup)
  }

  const DropMenuLink = () => {
    if(checkboxData){
      return <>
        <span className={'label'} config={config}>{label}</span>
        <ToggleSlider config={config} isChecked={checkboxData.isChecked} onToggle={checkboxData.onToggle}/>
      </>
    }

    if(customComponent){
      return <CustomLink {...props} componentName={customComponent} />
    }

    if(targetPopup){
      return <Link 
        className={'label ' + (secondary ? 'secondary' : '')}
        to={'/'}
        onClick={openPopup}
        config={config}
      >
        {label}
      </Link>
    }

    return <CoreLink
      {...linkProps}
      className={'label ' + (secondary ? 'secondary' : '')}
    />
  }

  return <StyledHeaderDropMenuItem className={className} config={config}>
    <DropMenuLink />
  </StyledHeaderDropMenuItem>;
}

const HeaderDropMenu = (props) => {
  const { parentRef, userHelper, notification, isOpen, toggleIsOpen, menuItems, mobileItems, openGlobalPopup, data } = props;
  const [ tokenFound, setTokenFound ] = useState(false);
  const wrapperRef = useRef(null);

  const closeMenu = () => {
    toggleIsOpen(false);
  }

  useOutsideAction(wrapperRef,closeMenu,parentRef)

  let config = useMemo(() => ToolHelper.getThemedConfig(props,'HeaderDropMenu.preset'),[props]);

  const getToken = () => {
    setTokenFound(true);
    userHelper.getMessagingToken((result) => {
      if(result.err){
        notification.add({
          title: config?.strings?.components?.notification?.NOTIFICATIONS_DENIED_TITLE,
          body: config?.strings?.components?.notification?.NOTIFICATIONS_DENIED_MESSAGE
        })
        setTokenFound(false);
      }
      else{
        setTokenFound(true);
      }
    })
  }

  return <StyledHeaderDropMenu config={config} isOpen={isOpen} ref={wrapperRef}>
    <ul>
      <div className={'main-items'}>
        {menuItems?.map(menuItem => 
          <HeaderDropMenuItem linkProps={menuItem} key={menuItem.label} config={config} data={data} openGlobalPopup={openGlobalPopup} />
        )}
      </div>
      <div className={'mobile-items'}>
        {mobileItems?.map(menuItem => 
          <HeaderDropMenuItem linkProps={menuItem} key={menuItem.label} config={config} data={data} openGlobalPopup={openGlobalPopup} />
        )}
      </div>
    </ul>
  </StyledHeaderDropMenu>
}

export default HeaderDropMenu;

/* style */

const StyledHeaderDropMenu = styled.div`
  position: absolute;
  background: ${props => props.config?.themeLayout?.background || 'transparent'};
  right: 0;
  padding: 20px;
  border-bottom-left-radius: ${props => (props.config?.layout?.BOX_BORDER_BOTTOM_LEFT_RADIUS || 0) * 2}px;
  border-bottom-right-radius: ${props => (props.config?.layout?.BOX_BORDER_BOTTOM_RIGHT_RADIUS || 0) * 2}px;
  border-bottom: 4px solid rgba(0,0,0,0.2);
  z-index: 9999;
  overflow: hidden;
  transition: transform 200ms linear;
  transform-origin: top;
  transform: scaleY(${props => props.isOpen ? 1 : 0});

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    width: 100%;
    left: 0;
  }

  ul{
    display: flex;

    .mobile-items{
      display: none;

      @media (max-width: ${BREAKPOINTS.PHONE}px){
        display: block;
      }
    }
  }
`;

const StyledHeaderDropMenuItem = styled.li`
  width: 200px;
  padding-right: 20px;

  .label{
    display: inline-block;
    padding: 0.7em 0;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.config?.themeLayout?.title};
    cursor: initial;
  }

  a.label{
    width: 100%;
    color: #009B67;
    cursor: pointer;

    &.secondary{
      color: ${props => props.config?.themeLayout?.title};
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px){    
    &.mobile {
      display: none;
    }
  }
    
  @media (min-width:${BREAKPOINTS.PHONE+1}px) and (max-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.tablet {
      display: none;
    }
  }

  @media (min-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.desktop {
      display: none;
    }
  } 
`
